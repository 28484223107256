<template>
	<div>
	  <h1 class="type" v-if="detail.afterStatus === 0">状态 : 待处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 1">状态 : 待处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 2">状态 : 已处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 4">状态 : 已处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 5">状态 : 已处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 3">状态 : 已取消</h1>
	  <el-card class="box-card">
		<div style="margin-left: 30px">
		  <div class="box"></div>
		  <h2 class="ntext">买家信息</h2>
		</div>
		<div class="text item">
		  <div class="dcenter">
			<div class="main">
			  <div class="shorttext">用户账号</div>
			  <div class="shorttext">用户昵称</div>
			  <div class="shorttext">下单时间</div>
			  <div class="shorttext">支付方式</div>
			</div>
		  </div>
		  <div class="dcenter">
			<div class="main">
			  <div class="longtext">{{ mobile }}</div>
			  <div class="longtext">{{ nickName }}</div>
			  <div class="longtext">{{ detail.createTime }}</div>
			  <div class="longtext" v-if="payType == '0'">{{ "未支付" }}</div>
			  <div class="longtext" v-if="payType == '1'">{{ "白条" }}</div>
			  <div class="longtext" v-if="payType == '2'">{{ "支付宝" }}</div>
			  <div class="longtext" v-if="payType == '3'">{{ "微信" }}</div>
			  <div class="longtext" v-if="payType == '4'">{{ "农行" }}</div>
			</div>
		  </div>
		</div>

		<div style="margin-left: 30px">
		  <div class="box"></div>
		  <h2 class="ntext">申请售后信息</h2>
		</div>

		<div class="text item">
		  <div class="domain">
			<div>
			  <span class="autoheader">售后类型</span>
			  <p>{{ detail.afterType == 1 ? "换货" : "退款" }}</p>
			</div>
		  </div>
      <div class="domain">
			<div>
			  <span class="autoheader">售后门店</span>
			  <p>{{ detail.storeName }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">售后编号</span>
			  <p>{{ detail.afterNo }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">关联订单编号</span>
			  <a @click="Jump(detail.orderId)" class="order" href="#">
				{{ $route.query.orderNo }}
			  </a>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">申请退款金额</span>
			  <p>{{ detail.afterAmountStr }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader" style="margin-right: 20px">实际退款</span>
			  ¥ <input v-if="detail.afterStatus == '1' || detail.afterStatus == '0'" type="text" v-model="detail.afterRealAmountStr" />
			  <span v-else>{{detail.afterRealAmountStr}}</span>
			</div>
      </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">售后原因</span>
			  <p>{{ detail.afterReason }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">申请时间</span>
			  <p>{{ detail.createTime }}</p>
			</div>
		  </div>
		  <div class="domain" v-if=" detail.afterStatus != '0' && detail.afterStatus == '1' && detail.afterStatus == '3'">
			<div>
			  <span class="autoheader">处理时间</span>
			  <p style="color: red">{{ detail.planTime }}</p>
			</div>
		  </div>
		  <div class="domain" v-if="detail.afterStatus == '4'">
			<div>
			  <span class="autoheader">退款方式</span>
			  <p style="color: red">原路返回</p>
			</div>
		  </div>
		  <div class="domain" v-if="detail.afterStatus == '2'">
			<div>
			  <span class="autoheader">驳回原因</span>
			  <p style="color: red">{{ detail.rejectReason }}</p>
			</div>
		  </div>
		  <div class="domain mainfooter">
			<div>
			  <span class="autoheader">申请说明</span>
			  <p class="order">{{ detail.afterExplain }}</p>
			</div>
		  </div>
		  <div class="domain mainfooter">
			<div class="mainfooters">
			  <span class="autoheaders">图片凭证</span>
			  <template v-if="preview">
				<img-preview :src="src" :onClick="closeImgPreview" :onKeydown="keydownCloseImgPreview"></img-preview>
			  </template>
			  <img :src="item" alt="" v-for="(item, index) in afterImageList" :key="index" @click="previewImage(item)" />
			</div>
		  </div>
		</div>
		<div style="margin-left: 30px">
		  <div class="box"></div>
		  <h2 class="ntext">退款商品</h2>
		</div>
		<div class="text item">
		  <div class="dcenter">
			<div class="main">
			  <div class="shorttext">商品图片</div>
			  <div class="shorttext">商品名称</div>
			  <div class="shorttext">规格</div>
			  <div class="shorttext">数量</div>
			  <div class="shorttext">单价</div>
			  <div class="shorttext">小计</div>
			  <div class="shorttext">实付</div>
			  <div class="shorttext">退款金额</div>
			</div>
		  </div>
		  <div class="dcenter" v-for="item in afterGoodsList" :key="item.id">
			<div class="main">
			  <div class="longtext">
				<img :src="item.mainImageUrl" alt="" />
			  </div>
			  <div class="longtext">{{ item.goodsName }}</div>
			  <div class="longtext">{{ item.goodsUnit }}</div>
			  <div class="longtext">{{ item.cartNum }}</div>
			  <div class="longtext">¥{{ item.goodsPrice }}</div>
			  <div class="longtext">
				¥{{item.goodsPrice*item.goodsNum}}
			  </div>
			  <div class="longtext">¥{{ commonUtils.accMul(item.goodsPrice,item.goodsNum)}}</div>
			  <div class="longtext">¥{{ commonUtils.accMul(item.goodsPrice,item.goodsNum )}}</div>
			</div>
		  </div>
		</div>
		<div class="footdokter">
		  <el-button type="primary" class="btn" v-if="detail.afterStatus == '1' || detail.afterStatus == '0'"
			@click="Agree">同意</el-button>
		  <el-button class="btn" v-if="detail.afterStatus == '1' || detail.afterStatus == '0'" @click="refused">拒绝
		  </el-button>
		  <el-button class="btn" @click="$router.push('/order/refund/list')">返回</el-button>
		</div>
		<el-form :model="form" :rules="rules" ref="form">
		  <el-dialog title="驳回订单" :visible.sync="dialogVisible" width="30%" :modal-append-to-body="false">
			<el-form-item prop="rejectReason">
			  <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="form.rejectReason"
				style="margin-bottom: 30px">
			  </el-input>
			</el-form-item>
			<!-- 拒绝成功后用户是否可以再次申请售后&emsp; -->
			<el-form-item label="拒绝成功后用户是否可以再次申请售后" prop="resourisAfterAgaince">
			  <el-radio-group v-model="form.isAfterAgain">
				<el-radio label="true">允许</el-radio>
				<el-radio label="false">不允许</el-radio>
			  </el-radio-group>
			</el-form-item>
			<span slot="footer" class="dialog-footer">
			  <el-button @click="dialogVisible = false">取 消</el-button>
			  <el-button type="primary" @click="submit('form')">确 定</el-button>
			</span>
		  </el-dialog>
		</el-form>


		<el-dialog title="确认退款" :visible.sync="isShow" width="30%" :modal-append-to-body="false">

		  <p class="tekst">
        申请退款金额: ￥<span style="color: black">{{ detail.afterAmountStr }}</span>
		  </p>
      <p class="tekst">
        实际退款金额:  ¥ <el-input style="width: 50%;" type="text" v-model="detail.afterRealAmountStr" />
      </p>
      <p class="tekst">
        订单责任归属: <el-select v-model="detail.dutyBelong" placeholder="请选择">
              <el-option label="平台" value="1"></el-option>
              <el-option label="商家" value="2"></el-option>
            </el-select>
		  </p>
      <p class="tekst">
        平台责任：费用平台出，商家责任：费用商家出，
      </p>
		  <span slot="footer" class="dialog-footer">
			  <el-button @click="isShow = false">取 消</el-button>
			  <el-button type="primary" @click="StandardSubmit(1)">平台垫付</el-button>
			  <el-button type="primary" @click="StandardSubmit(0)">商家退费</el-button>
		  </span>
		</el-dialog>
	  </el-card>
	</div>
  </template>

  <script>
	import {
	  orderAfterDetail,
	  oredrGetCombineId,
	  oredrAfterRefund,
	  orderAfterReject,
	} from "@/api/order";
	import imgPreview from "./ImageBig";
	export default {
	  data() {
		return {
		  afterAmountStr: "", //退款金额
		  detail: {}, //售后订单详情
		  memberId: "", //用户账号
		  mobile:"",
		  nickName: "", //昵称
		  createTime: "", //下单时间
		  payType: "", //支付方式
		  afterImageList: [], //图片详情
		  preview: false,
		  src: "",
		  afterGoodsList: [], //商品详情
		  dialogVisible: false,
		  textarea: "", //驳回原因
		  value: "true", //是否再次售后
		  isShow: false, //退款确认
		  form: {
			rejectReason: "",
			isAfterAgain: "true",
			id: "",
		  },
		  rules: {
			rejectReason: [{
			  required: true,
			  message: '请输入驳回原因',
			  trigger: 'blur'
			}, ],
		  }
		};
	  },
	  components: {
		"img-preview": imgPreview,
	  },
	  created() {
	    this.getOrderAfterDetail(this.$route.query.id);
	  },
	  methods: {
	    getOrderAfterDetail(id){
        orderAfterDetail(id).then((res) => {
          this.detail = res.data;
          if(this.detail.afterStatus == '1' || this.detail.afterStatus == '0'){
            this.detail.afterRealAmountStr = res.data.afterAmountStr
          }else{
            this.detail.afterRealAmountStr = res.data.afterRealAmountStr
          }

          this.form.id = res.data.id
          if (res.data.memberInfo) {
            this.memberId = res.data.memberInfo.id;
            this.nickName = res.data.memberInfo.nickName;
            this.createTime = res.data.memberInfo.createTime;
            this.mobile = res.data.memberInfo.mobile
          }
          this.payType = res.data.payType;
          this.afterAmountStr = res.data.afterAmountStr;
          this.afterImageList = res.data.afterImageList;
          this.afterGoodsList = res.data.afterGoodsList;
          if(this.detail.dutyBelong == 0){
            this.detail.dutyBelong = null;
          }
        });
      },
		previewImage(src) {
		  this.preview = true;
		  this.src = src;
		},
		keydownCloseImgPreview(e) {
		  if (e && e.keyCode == 27) {
			this.preview = false;
		  }
		},
		closeImgPreview() {
		  this.preview = false;
		},
		//跳转
		Jump(val) {
		  oredrGetCombineId({
			orderId: val
		  }).then((res) => {
			this.$router.push({
			  path: "/order/detail",
			  query: {
				id: res.data
			  },
			});
		  });
		},
		// 同意
		Agree() {
		  this.isShow = true;
		},
		//拒绝
		refused() {
		  this.dialogVisible = true;
		},
		// 拒绝确定
		submit(form) {
		  this.$refs[form].validate((valid) => {
			if (valid) {
			  orderAfterReject(this.form).then((res) => {
				this.dialogVisible = false;
				this.$router.push("/order/refund/list");
			  });
			} else {
			  return false;
			}
		  });

		},
		// 退款确认
		StandardSubmit(isPartner) {


		  if( this.commonUtils.accMul(this.detail.afterRealAmountStr,100) > this.commonUtils.accMul(this.detail.afterAmountStr,100) ){
        this.$notify({
          message: "退款金额不能大于申请金额",
          type: "warning",
        });
        return;
      }

		  if(!this.detail.dutyBelong){
        this.$notify({
          message: "请选择责任归属",
          type: "warning",
        });
        return;
      }
		  let obj = {
        afterId: this.detail.id,
        dutyBelong: this.detail.dutyBelong,
			  refundAmount: this.commonUtils.accMul(this.detail.afterRealAmountStr,100),
        isPartner: isPartner,
		  };

		  console.log(obj);

		  oredrAfterRefund(obj).then((res) => {
          if (res.status == 10000) {
            this.$notify({title: "退款", message: "退款成功", type: "success",});
            this.getOrderAfterDetail(this.$route.query.id);
            this.isShow = false;
          }
		  });
		},
	  },
	};
  </script>

  <style lang='less' scoped>
	.type {
	  font-weight: 700;
	  margin: 20px 0;
	  font-size: 20px;
	  margin-left: 25px;
	}

	.text {
	  font-size: 14px;
	}

	.item {
	  padding: 28px 30px;
	}

	.box-card {
	  width: 100%;
	}

	.box {
	  width: 15px;
	  height: 15px;
	  background-color: #8400ff;
	  display: inline-block;
	}

	.ntext {
	  display: inline-block;
	  margin-left: 5px;
	  font-weight: 700;
	  font-size: 18px;
	}

	.dcenter {
	  width: 100%;
	  border: 1px solid #efefef;

	  .main {
		display: flex;

		.shorttext {
		  border-right: 1px solid #efefef;
		  width: 360px;
		  height: 40px;
		  text-align: center;
		  line-height: 40px;
		  background-color: #f9fafc;
		  font-size: 14px;
		  font-weight: 700;
		}

		.longtext {
		  padding: 5px;
		  overflow: hidden; //超出的文本隐藏
		  text-overflow: ellipsis; //溢出用省略号显示
		  white-space: nowrap; //溢出不换行
		  border-right: 1px solid #efefef;
		  width: 360px;
		  height: 80px;
		  text-align: center;
		  line-height: 80px;
		  font-size: 14px;

		  img {
			width: 80px;
			height: 80px;
		  }
		}
	  }
	}

	.mainfooter {
	  border-bottom: 1px solid #efefef;
	}

	.domain {
	  .order {
		margin-left: 25px;
		color: #0066ff;
	  }

	  //   border: 1px solid #efefef;
	  border-right: 1px solid #efefef;
	  border-left: 1px solid #efefef;
	  border-top: 1px solid #efefef;
	  width: 100%;

	  .autoheader {
		display: inline-block;
		height: 46px;
		width: 160px;
		border-right: 1px solid #efefef;
		background: #f9fafc;
		text-align: center;
		line-height: 46px;
		font-weight: 600;
		color: #333333;
		font-size: 14px;
	  }

	  .autoheaders {
		display: inline-block;
		height: 80px;
		width: 160px;
		border-right: 1px solid #efefef;
		background: #f9fafc;
		text-align: center;
		line-height: 80px;
		font-weight: 600;
		color: #333333;
		font-size: 14px;
	  }

	  img {
		height: 80px;
		width: 80px;
	  }

	  p {
		display: inline-block;
		margin-left: 30px;
	  }

	  input {
		display: inline-block;
		width: 60px;
		height: 24px;
		outline: none;
		border: 1px solid #efefef;
		padding: 5px;
		font-weight: normal;
	  }

	  .mainfooters {
		height: 80px;
		display: flex;
	  }
	}

	.footdokter {
	  display: flex;
	  justify-content: center;

	  .btn {
		width: 100px;
	  }
	}

	.tekst {
	  font-size: 16px;
	  color: #000;
	  margin-top: 15px;
	  margin-left: 30px;
	}
  </style>
